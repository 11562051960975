<template>
    <div class="consult">
     <div style="position:relative">
          <img class="bannerImg" src="../assets/images/consult.png" alt="">
        <div class="banner">
            <p class="title">智算中心是新型生产力</p>
            <p>正在赋能千行百业</p>
        </div>
      </div>
        <div class="demand">
            <div class="secondary-title"><span class="secondary-number">1</span>*需求描述</div>
            <el-input v-model="form.reqDesc" type="textarea" resize="none"></el-input>
            <div style="margin: 0.2rem 0" class="secondary-title"><span class="secondary-number">2</span>*请勾选您感兴趣的产品或解决方案</div>
            <div class="product">
                <el-form ref="form" :model="form" label-width="1rem">
                    <el-form-item label="*产品">
                        <!--<el-input v-model="form.name" placeholder=""></el-input>-->
                        <el-tabs type="border-card">
                            <el-tab-pane label="软件">
                                <el-checkbox-group v-model="form.productInfo">
                                    <el-checkbox label="关键应用主机" name="关键应用主机"></el-checkbox>
                                    <el-checkbox label="浪潮商业机器" name="浪潮商业机器"></el-checkbox>
                                    <el-checkbox label="存储" name="存储"></el-checkbox>
                                    <el-checkbox label="人工智能" name="人工智能"></el-checkbox>
                                    <el-checkbox label="高性能" name="高性能"></el-checkbox>
                                    <el-checkbox label="实训用模型DEMO" name="实训用模型DEMO"></el-checkbox>
                                    <el-checkbox label="大数据" name="大数据"></el-checkbox>
                                    <el-checkbox label="安全" name="安全"></el-checkbox>
                                    <el-checkbox label="AI私有云平台" name="AI私有云平台"></el-checkbox>
                                    <el-checkbox label="智算中心云平台" name="智算中心云平台"></el-checkbox>
                                    <el-checkbox label="唱名识别" name="唱名识别"></el-checkbox>
                                    <el-checkbox label="语音识别" name="语音识别"></el-checkbox>
                                    <el-checkbox label="票据文字识别" name="票据文字识别"></el-checkbox>
                                </el-checkbox-group>
                            </el-tab-pane>
                            <el-tab-pane label="硬件">
                                <el-checkbox-group v-model="form.productInfo">
                                    <el-checkbox label="机塔一体式服务器" name="机塔一体式服务器"></el-checkbox>
                                    <el-checkbox label="AI边缘盒子" name="AI边缘盒子"></el-checkbox>
                                    <el-checkbox label="智能计算加速卡" name="智能计算加速卡"></el-checkbox>
                                    <el-checkbox label="2U机架式AI服务器" name="2U机架式AI服务器"></el-checkbox>
                                    <el-checkbox label="全自动锁螺丝教具" name="全自动锁螺丝教具"></el-checkbox>
                                    <el-checkbox label="智能计算边缘加速卡" name="智能计算边缘加速卡"></el-checkbox>
                                    <el-checkbox label="4U机架式AI服务器" name="4U机架式AI服务器"></el-checkbox>
                                    <el-checkbox label="螺母表面瑕疵检测教具" name="螺母表面瑕疵检测教具"></el-checkbox>
                                    <el-checkbox label="智能象棋对弈机器人" name="智能象棋对弈机器人"></el-checkbox>
                                </el-checkbox-group>
                            </el-tab-pane>
                            <el-tab-pane label="内容">
                                <el-checkbox-group v-model="form.productInfo">
                                    <el-checkbox label="教材" name="教材"></el-checkbox>
                                    <el-checkbox label="PPT、教案" name="PPT、教案"></el-checkbox>
                                    <el-checkbox label="实验指导手册" name="实验指导手册"></el-checkbox>
                                    <el-checkbox label="多媒体课程" name="多媒体课程"></el-checkbox>
                                </el-checkbox-group>
                            </el-tab-pane>
                            <el-tab-pane label="服务">
                                <el-checkbox-group v-model="form.productInfo">
                                    <el-checkbox label="1+X相关服务" name="1+X相关服务"></el-checkbox>
                                    <el-checkbox label="合作共建-AI课程" name="合作共建-AI课程"></el-checkbox>
                                    <el-checkbox label="合作共建-AI专业" name="合作共建-AI专业"></el-checkbox>
                                    <el-checkbox label="合作共建-AI学院" name="合作共建-AI学院"></el-checkbox>
                                </el-checkbox-group>
                            </el-tab-pane>
                        </el-tabs>

                    </el-form-item>
                    <el-form-item label="*解决方案">
                        <el-checkbox-group v-model="form.solustion">
                            <el-checkbox v-for="item in programme" :label="item" :key="item">{{ item }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="*已选项目">
                        <el-checkbox-group v-model="projectList"  >
                            <el-checkbox @change="projectChange(item)" v-for="item in projectList" :label="item" :key="item">{{ item }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                </el-form>
            </div>

            <div class="product contact-information">
                <el-form ref="form" :model="form" :rules="rules" label-width="0.8rem">
                    <el-row>
                        <el-col :span="24">
                            <el-col :span="8">
                                <el-form-item label="姓名" prop="userName">
                                    <el-input v-model="form.userName" placeholder=""></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="电话" prop="phone">
                                    <el-input v-model="form.phone" placeholder=""></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="邮箱" prop="email">
                                    <el-input v-model="form.email" placeholder=""></el-input>
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </el-row>
                </el-form>

            </div>
            <div class="tips">
                <el-button style="width: 3rem;background-color: #196ECE;color: #fff" @click="submit" :loading="loading">提交</el-button>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import userService from "@/api/api";

export default {
    components: { },
    watch: {
        'form.productInfo' :{
            handler(val){
                this.projectList = val.concat(this.form.solustion)
            }
        },
        'form.solustion' :{
            handler(val){
                this.projectList = val.concat(this.form.productInfo)
            }
        },
    },
    data() {
        return {
            loading: false,
            projectList: [],
            programme: ['工业视觉检测智能处理中心', '教育产品', '智慧交通'],
            activeIndex: '/inquiry',
            describeShow: false,
            form: {
                productInfo: [],
                solustion: [],
                userName: '',
                phone: '',
                email: '',
                reqDesc: '',
            },
            rules: {
                userName: [
                    {required: true, message: '请输入姓名', trigger: 'blur'},
                ],
                phone: [
                    {required: true, message: '请输入电话', trigger: 'blur'}
                ],
                email: [
                    {required: true, message: '请输入邮箱', trigger: 'blur'}
                ],

            }
        };
    },
    async mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        submit() {
            let params = {
                ...this.form,
            }
            if (this.form.solustion.length == 0) {
                this.$message.warning('请勾选解决方案！')
                return
            }
            if (this.form.productInfo.length == 0) {
                this.$message.warning('请勾选产品！')
            }
            if (!this.form.reqDesc) {
                this.$message.warning('请输入需求！')
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true
                    userService.advisory(params).then(res => {
                        if (res.code == 200) {
                            this.$message.success('提交成功！')
                            this.form = {   productInfo: [],
                                            solustion: [],
                                            userName: '',
                                            phone: '',
                                            email: '',
                                            reqDesc: '',}
                        }
                        this.loading = false;
                    })
                        .catch(err => {
                            this.loading = false;
                        });
                } else {
                    return false;
                }
            });
        },
        projectChange(data) {
            this.form.productInfo.forEach((item, index) => {
                if (item == data) {
                    this.form.productInfo.splice(index, 1)
                }
            })
            this.form.solustion.forEach((item, index) => {
                if (item == data) {
                    this.form.solustion.splice(index, 1)
                }
            })
        }
    },
};
</script>

<style lang="scss">
.consult{
  .banner{
       left:10%;
        transform: translateY(-60%);
        p:nth-child(1){
         display: inline-block;
         line-height: 0.5rem;
         font-size: 0.4rem;
        }
       p:nth-child(2){
        font-size: 0.4rem;
        margin-top: 0.2rem;
        display: block;
        border-bottom: none;
       }
    }  
}

.el-message {
    font-size: 18px;
}
.demand {
    width: 80%;
    margin: 0.5rem auto 0;
    .demand-title {
        font-size: 0.25rem;
        color: #fff;
        margin-top: 0.2rem;
    }

    .secondary-title {
        margin-top: 0.2rem;
        margin-bottom: 0.1rem;
        font-size: 0.18rem;

        .secondary-number {
            font-weight: bold;
            display: inline-block;
            width: 0.2rem;
            height: 0.2rem;
            text-align: center;
            line-height: 0.2rem;
            border-radius: 50%;
            margin-right: 0.1rem;
            background-color: #fff;
            color: #244492;
        }
    }

    .product {
        border: 1px solid #26357E;
        //background-color: #0B1249;
        border-radius: 4px;
        padding: 0.15rem;

        .el-form-item__label {
            font-size: 0.1rem;
            height: 0.5rem;
            //color: white;
            line-height: 0.5rem;
        }
        

        .el-input__inner {
            height: 0.35rem;
            line-height: 0.35rem;
            font-size: 0.1rem;
            border-radius: 0.04rem;
            border: 0.01rem solid #dcdfe6;
        }

        .el-form-item__content {
            line-height: 0.5rem;
        }

        .el-form-item {
            margin-bottom: 0.2rem;
        }

        .el-tabs__nav-wrap {
            /*<!--margin-bottom: -0.0rem;-->*/
            margin: 0rem;
            padding: 0rem;
        }

        .el-tabs--border-card > .el-tabs__header .el-tabs__item {
            border: 0.02rem solid transparent;
            margin: 0.00rem 0.05rem 0;
        }

        .el-tabs__item {
            padding: 0 0.2rem;
            height: 0.4rem;
            line-height: 0.4rem;
            font-size: 0.14rem;
            margin: 0.04rem;
            padding: 0.03rem;
        }

        .demand .product .el-tabs--border-card > .el-tabs__header .el-tabs__item {
            border: 0.02rem solid transparent;
            margin: 0.05rem 0.05rem 0;
        }

        .el-tabs--top.el-tabs--border-card .el-tabs__item:nth-child(2), .el-tabs--top.el-tabs--card .el-tabs__item:nth-child(2), .el-tabs--top .el-tabs--left .el-tabs__item:nth-child(2), .el-tabs--top .el-tabs--right .el-tabs__item:nth-child(2), .el-tabs--bottom.el-tabs--border-card .el-tabs__item:nth-child(2), .el-tabs--bottom.el-tabs--card .el-tabs__item:nth-child(2), .el-tabs--bottom .el-tabs--left .el-tabs__item:nth-child(2), .el-tabs--bottom .el-tabs--right .el-tabs__item:nth-child(2) {
            padding-left: 0.2rem;
        }

        .el-tabs--border-card > .el-tabs__content {
            padding: 0.15rem;
        }

        .el-checkbox {
            font-size: 0.14rem;
            margin: 0.04rem;
            padding: 0.03rem;
        }

        .el-checkbox__inner {
            border: 0.01rem solid #dcdfe6;
            border-radius: 0.02rem;
            width: 0.14rem;
            height: 0.14rem;
            z-index: 0;
        }

        .el-checkbox__label {
            padding-left: 0.1rem;
            line-height: 0.19rem;
            font-size: 0.14rem;
        }

        .el-checkbox-button__inner {
            border: 0.01rem solid #dcdfe6;
        }

        .el-checkbox-button__inner {
            padding: 0.12rem 0.2rem;
            font-size: 0.14rem;
            border-radius: 0;
        }

        .el-input::-webkit-scrollbar {
            width: 0.06rem;
        }
    }

    .contact-information {
        margin-top: 0.2rem;
    }
}

.tips {
    text-align: center;
    margin-top: 0.3rem;
}
</style>
<style>
</style>
</script>
